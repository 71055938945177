import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EndpointService } from '@bmng/services/endpoint.service';
import { AiContetType, AiGenerationRequest, AiTranslationRequest, AiTranslationResponse } from './ai-translation-request.interface';

@Injectable()
export class AiTextService extends EndpointService {
    constructor(
        http: HttpClient,
    ) {
        super(http);
    }

    public translate(contextType: AiContetType, request: AiTranslationRequest): Observable<AiTranslationResponse> {
        const url  = `${EndpointService.getBmBackendUrl()}/api/ai/content/${contextType}/translate`;
        return this.httpPost<AiTranslationResponse>(url, request, EndpointService.HTTP_HEADERS);
    }

    public generate(contextType: AiContetType, request: AiGenerationRequest): Observable<AiTranslationResponse> {
        const url  = `${EndpointService.getBmBackendUrl()}/api/ai/content/${contextType}/generate`;
        return this.httpPost<AiTranslationResponse>(
            url,
            request,
            EndpointService.HTTP_HEADERS,
        );
    }
}
